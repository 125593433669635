import parse from "csv-parse";
import firebase from "firebase";
import _, { zipObject } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion, Button, Icon, Segment } from "semantic-ui-react";
import { v4 } from "uuid";
import EditButton from "../../../Componentes/EditButton";
import { Column, DataTable } from "../../../Componentes/Tabela";
import { DataTableVazia } from "../../../Componentes/TabelaVazia";
import HeaderPadrao from "../../../Componentes/HeaderPadrao";
import { textFileReader } from "../../../Utils/File";
import { NovaLojaForm } from "../NovaLojaForm";
import { formatLojas, onImport } from "./ImportarLojaMethods";
import { parseCsv, downloadCSV } from "../../../Utils/Csv";

const csvExemplo = `cnpj; nome fantasia; razao social; logradouro; cep; numero; bairro; cidade; estado; sigla; pais
03.384.134/0001-41;exemplo de nome fantasia 1; exemplo de razao social 1; Avenida Volta Redonda; 87208-156; 123;Zona 07; Cianorte; Parana; PR; Brasil
91.554.991/0001-00;exemplo de nome fantasia 2; ; Rua Pastor Fugmann; 84070-030; 123; Nova Russia; Ponta Grossa; Parana; PR; Brasil`;

const DataTableHeader = props => (
	<div className="header-label" onClick={props.onClick}>
		<span>{props.label}</span>
	</div>
);

class ImportarLoja extends Component {
	state = {
		carregando: false,
		isOpen: false,
		lojas: [],
		nome: null,
		index: -1,
		loja: {},
		enviado: false,
		activeIndex: -1,
		searchBox: "",
		erroCodigo: "",
		lojasSemEndereco: [],
	};

	removerArquivo = (/* event */) => this.setState({ nome: null, lojas: [] });
	selectFile = event => document.getElementById("arquivo").click();
	cancelarEdicao = event => this.setState({ index: -1, loja: {} });

	handleDownLoad = (csv, title) => {
		downloadCSV(csv, title);
	};

	handleImport = () => {
		onImport(this.state.lojas, this.props.idEmpresa, this.setState.bind(this));
	};

	onSubmit = event => {
		const lojas = [...this.state.lojas];
		lojas[this.state.index] = {
			...this.state.loja,
		};
		this.setState({
			lojas,
			index: -1,
			loja: {},
		});
	};

	editItem = (index, data) => event =>
		this.setState({
			index: index,
			loja: { ...data },
		});

	onSelectFile = event => {
		var input = document.getElementById("arquivo");
		if (input.files) {
			Array.from(input.files).map(file =>
				textFileReader(file).then(({ file, data }) => {
					var regEx = new RegExp(".csv$");
					if (regEx.test(file.name)) {
						parseCsv(data, "cnpj", out => {
							formatLojas(file.name, out)
								.then(({ lojasComEndereco, lojasSemEndereco }) => {
									this.setState(
										{
											lojas: lojasComEndereco,
											lojasSemEndereco: lojasSemEndereco,
											nome: file.name,
										},
										() => {
											input.value = "";
										}
									);
								})
								.catch(error => console.error("Erro ao formatar lojas:", error));
						});
					} else {
						alert("arquivo invalido por favor siga nosso arquivo de exemplo");
					}
				})
			);
		}
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	observacao = (t, activeIndex, handleClick) => {
		return (
			<Accordion>
				<Accordion.Title
					active={activeIndex === 0}
					index={0}
					onClick={handleClick}
					style={{ color: "#662780" }}>
					<Icon name="dropdown" />
					{t("observacoes")}
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 0}>
					<ul>
						<li>{t("nao.utilizar.caracteres.especiais")}</li>
					</ul>
				</Accordion.Content>
			</Accordion>
		);
	};

	render() {
		const { t } = this.props;
		const { nome, loja, lojas, carregando, enviado, lojasSemEndereco } = this.state;

		if (!_.isEmpty(loja)) {
			return (
				<div>
					<HeaderPadrao
						icone="boxes"
						titulo={t("importar")}
						subtitulo={t("importar.loja.do.csv")}
					/>
					<Segment className="purple-gradient-87">
						<NovaLojaForm
							scope={this}
							t={t}
							searchBox={this.state.searchBox}
							location={location}
							erroCodigo={this.state.erroCodigo}
							carregando={this.state.carregando}
							loja={loja}
							empresa={this.props.empresa}
							onSubmit={event => this.onSubmit(event)}>
							<div
								style={{
									display: "table",
									width: "100%",
									marginTop: "1em",
									marginRight: 10,
								}}>
								<Button
									className="green-gradient-light"
									content={t("salvar")}
									style={{ marginRight: 12 }}
									disabled={this.state.carregando}
									icon="check"
									color="green"
									floated="right"></Button>
								<Button
									content={t("cancelar")}
									icon="left arrow"
									color="red"
									floated="right"
									onClick={this.cancelarEdicao.bind(this)}
									disabled={this.state.carregando}></Button>
							</div>
						</NovaLojaForm>
					</Segment>
				</div>
			);
		}

		return (
			<div>
				<div className="importar-csv-header" style={{ marginBottom: nome ? 0 : 35 }}>
					<div>
						<HeaderPadrao
							icone="file"
							titulo={t("importar")}
							subtitulo={t("importar.loja.do.csv")}
						/>
						<p>{t("importar.lojas.csv")}</p>
						{this.observacao(t, this.state.activeIndex, this.handleClick)}
					</div>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div
							style={{
								color: "gray",
								cursor: "pointer",
								border: "1px solid #eee",
								padding: 10,
								borderRadius: 3,
								width: "150px",
							}}
							className="dowload-exemplo-csv"
							onClick={() =>
								this.handleDownLoad(csvExemplo, "lojas_csv_exemplo.csv")
							}>
							<i aria-hidden="true" class="download icon" />
							{t("csv.exemplo")}
						</div>
					</div>
				</div>

				{enviado ? (
					<div
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<div
							onClick={() =>
								this.setState({
									nome: null,
									enviado: false,
									lojas: [],
								})
							}
							style={{
								background: "#4CD964",
								padding: 30,
								borderRadius: 40,
								color: "#fff",
								fontSize: 25,
								marginTop: 100,
							}}>
							<Icon name="checkmark" />{" "}
							{t("lojas.importados").replace(/\{number\}/, lojas.length)}
						</div>
					</div>
				) : nome ? (
					<>
						<div className="importar-csv-header">
							<h3 style={{ display: "inline" }}>{nome}</h3>
							{carregando && (
								<span>
									<Icon size="large" loading name="spinner" />
									Importando...
								</span>
							)}
							<div style={{ display: "flex", float: "right" }}>
								<Button
									disabled={carregando}
									icon="download"
									basic
									color="green"
									onClick={() => this.handleImport()}
									content={t("concluir.importacao")}></Button>
								<Button
									disabled={carregando}
									icon="x"
									basic
									color="red"
									onClick={this.removerArquivo.bind(this)}
									content={t("cancelar.importacao")}></Button>
							</div>
						</div>
						<DataTable dataSource={lojas} header={DataTableHeader}>
							<Column label={t("cnpj")} property="codigo" />
							<Column label={t("nome.fantasia")} property="fantasia" />
							<Column label={t("endereco")} property="endereco.endereco" />

							<Column
								label={t("editar")}
								t={t}
								cell={props => (
									<EditButton
										t={props.t}
										onClick={this.editItem(props.rowIndex, props.rowData).bind(
											this
										)}
									/>
								)}
							/>
						</DataTable>
						{lojasSemEndereco.length > 0 && (
							<div
								className="importar-csv-header"
								style={{ marginBottom: nome ? 0 : 35 }}>
								<div>
									<p>{t("erro.localizacao.lojas")}</p>
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div
										style={{
											color: "gray",
											cursor: "pointer",
											border: "1px solid #eee",
											padding: 10,
											borderRadius: 3,
											width: "180px",
										}}
										className="dowload-exemplo-csv"
										onClick={() =>
											this.handleDownLoad(
												lojasSemEndereco,
												"lojas_sem_endereco.csv"
											)
										}>
										<i aria-hidden="true" class="download icon" />
										{t("csv.lojas.sem.endereco")}
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						<DataTableVazia
							selectFile={this.selectFile}
							onSelectFile={this.onSelectFile}
							t={t}
							header={DataTableHeader}
							title={t("selecionar.csv.com.lojas")}
						/>
					</>
				)}
			</div>
		);
	}
}

export default connect(state => ({
	idEmpresa: state.AutenticacaoReducer.empresa.uid,
	empresa: state.AutenticacaoReducer.empresa,
}))(ImportarLoja);
