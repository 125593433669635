import { Required } from "./Required";

export const Field = props => {
	const { disabled, type, name, label, text, onBlur, onChange, required, width } = props;
	let wi = width ? { width } : {};
	return (
		<div style={{ marginBottom: "10px", ...wi }}>
			<label>
				{label} <Required required={required} />
			</label>
			<div className={`ui fluid ${disabled ? "disabled" : ""} input`}>
				<input
					{...props}
					name={name}
					type={type || "text"}
					value={text}
					required={required}
					disabled={disabled}
					onChange={onChange || (() => {})}
					onBlur={onBlur}
				/>
			</div>
		</div>
	);
};
