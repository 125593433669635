import parse from "csv-parse";

export const downloadCSV = (csv, title) => {
	// caso seja enviado um array
	if (Array.isArray(csv) || csv.length === 0) {
		csv = arrayToCsv(csv);
	}

	const blob = new Blob([csv], { type: "text/csv" });
	const link = document.createElement("a");
	const linkURL = URL.createObjectURL(blob);
	link.href = linkURL;
	link.target = "_blank";
	link.download = title;
	link.onclick = function () {
		document.body.removeChild(link);
	};
	document.body.appendChild(link);
	link.click();
	URL.revokeObjectURL(linkURL);
};

export const arrayToCsv = itens => {
	if (!Array.isArray(itens) || itens.length === 0) {
		console.log("O array deve ser não vazio.");
	}

	const headersCsv = Object.keys(itens[0]); // cabeçalhos do CSV
	const rows = itens.map(item =>
		headersCsv.map(header => formatCSVValue(item[header])).join(";")
	);

	return [headersCsv.join(";"), ...rows].join("\n");
};

const formatCSVValue = value => {
	if (value === null || value === undefined) {
		return "";
	}
	const stringValue = String(value);
	return stringValue.includes(",") ? `"${stringValue}"` : stringValue;
};

export const parseCsv = (file, lineToIgnore, call) => {
	const output = [];
	parse(file, {
		trim: true,
		delimiter: ";",
		comment: "#",
		skip_empty_lines: true,
		skip_lines_with_error: true,
		bom: false,
	})
		.on("readable", function () {
			let record;
			while ((record = this.read()) !== null) {
				if (record[0] && record[0] !== lineToIgnore) output.push(record);
			}
		})
		.on("end", function () {
			call(output);
		});
};
