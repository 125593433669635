import { Pagination } from "@material-ui/lab";
import { isObject } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Table, Loader, Placeholder, PlaceholderHeader, PlaceholderLine } from "semantic-ui-react";
import { generateArray } from "toolkit-extra";
import { LoggedUserInLog } from "../../../@types/Logs";
import { ModalShowBasicMap } from "../../../Componentes/ModalShowBasicMap";
import { FetchLogsReturn } from "../../../services/Log";
import { UserName, UserBasicInfo } from "./styles";

function mapDescriptionToLabel(t: TFunction<"translation", undefined>, description: string) {
	const [key, realDescription] = description.split(" - ");

	if (!key && !realDescription) return t("log-desconhecido");

	if (!key) return t(realDescription);

	const mappedKeys = {
		"log.criacao.roteiro": t("log.criacao.roteiro"),
		"log.update.roteiro": t("log.update.roteiro"),
		"log.cancelar.roteiro": t("log.cancelar.roteiro"),
		"log.ativar.recorrencia": t("log.ativar.recorrencia"),
		"log.selecao.promotor": t("log.selecao.promotor"),
		"log.cancelar.selecao.promotor": t("log.cancelar.selecao.promotor"),
		"log.erro.selecao.promotor": t("log.erro.selecao.promotor"),
		"log.delete.treinamento": t("log.delete.treinamento"),
		"log.create.treinamento": t("log.create.treinamento"),
		"log.update.treinamento": t("log.update.treinamento"),
		"log.criacao.recorrencia": t("log.criacao.recorrencia"),
		"log.delete.recorrencia": t("log.delete.recorrencia"),
		"log.update.recorrencia": t("log.update.recorrencia"),
		"log.update.recorrencia.ativada": t("log.update.recorrencia.ativada"),
		"log.update.recorrencia.desativada": t("log.update.recorrencia.desativada"),
		"log.criacao.proximas.recorrencias": t("log.criacao.proximas.recorrencias"),
		"log.delete.produto": t("log.delete.produto"),
		"log.criar.usuario": t("usuario-criado-com-sucesso"),
		"log.update.usuario": t("usuario-atualizado-com-sucesso"),
		"log.delete.usuario": t("usuario-excluido-com-sucesso"),
		"log.avaliar.oportunidade": t("oportunidade-avaliada-com-sucesso"),
		"log.update.avaliacao.oportunidade": t("log.update.avaliacao.oportunidade"),
	};

	const keyLabel = mappedKeys[key as keyof typeof mappedKeys];

	return `${keyLabel || t("log-desconhecido")} ${realDescription}`;
}

function generateLogSkeleton(quantity = 20) {
	const uids = generateArray(quantity);

	return uids.map(uid => (
		<Table.Row key={uid}>
			<Table.Cell>
				<Placeholder>
					<PlaceholderLine />
				</Placeholder>
			</Table.Cell>
			<Table.Cell>
				<Placeholder>
					<PlaceholderLine />
				</Placeholder>
			</Table.Cell>
			<Table.Cell>
				<Placeholder>
					<PlaceholderLine />
				</Placeholder>
			</Table.Cell>
			<Table.Cell>
				<Placeholder>
					<PlaceholderHeader>
						<PlaceholderLine />
						<PlaceholderLine />
					</PlaceholderHeader>
				</Placeholder>
			</Table.Cell>
			<Table.Cell>
				<Placeholder>
					<PlaceholderLine />
				</Placeholder>
			</Table.Cell>
			<Table.Cell>
				<Placeholder>
					<PlaceholderLine />
				</Placeholder>
			</Table.Cell>
		</Table.Row>
	));
}

interface LogListTableProps {
	isLoading: boolean;
	itemsPerPage: number;
	isFetching: boolean;
	data?: FetchLogsReturn;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
}

export function LogListTable({
	isLoading,
	itemsPerPage,
	data,
	isFetching,
	page,
	setPage,
}: LogListTableProps) {
	const { t } = useTranslation();

	function mapUserInformationToCell(user?: LoggedUserInLog) {
		if (!user) return t("nao-consta");

		return (
			<div>
				<UserName>{user.nome}</UserName>
				<UserBasicInfo>{user.uid}</UserBasicInfo>
				<UserBasicInfo>{user.email}</UserBasicInfo>
			</div>
		);
	}

	return (
		<Table color="violet" stackable>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>{t("tipo")}</Table.HeaderCell>
					<Table.HeaderCell>{t("data")}</Table.HeaderCell>
					<Table.HeaderCell>{t("hora")}</Table.HeaderCell>
					<Table.HeaderCell>{t("usuario")}</Table.HeaderCell>
					<Table.HeaderCell>IP</Table.HeaderCell>
					<Table.HeaderCell>{t("descricao")}</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{isLoading && generateLogSkeleton(itemsPerPage)}
				{data?.docs.map(
					({ acao, descricao, ip, data, _id, usuarioLogado, coordenadas }) => {
						return (
							<Table.Row key={_id}>
								<Table.Cell>{t(acao)}</Table.Cell>
								<Table.Cell>{moment(data).format("DD/MM/YYYY")}</Table.Cell>
								<Table.Cell>{moment(data).format("HH:mm:ss")}</Table.Cell>
								<Table.Cell>{mapUserInformationToCell(usuarioLogado)}</Table.Cell>
								<Table.Cell>
									{ip}
									{isObject(coordenadas) && (
										<ModalShowBasicMap
											lat={coordenadas.lat}
											lng={coordenadas.lng}
										/>
									)}
								</Table.Cell>
								<Table.Cell>{mapDescriptionToLabel(t, descricao)}</Table.Cell>
							</Table.Row>
						);
					}
				)}
			</Table.Body>

			<Table.Footer>
				<Table.Row textAlign={"center"}>
					<Table.HeaderCell colSpan="6">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}>
							<p>
								<b>Logs:</b> {data?.total}
							</p>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									flex: 1,
								}}>
								<Pagination
									disabled={isFetching}
									shape="rounded"
									count={data?.totalPages}
									page={page}
									onChange={(_, page) => setPage(page)}
								/>
							</div>
							<Loader active={isFetching} inline />
						</div>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
		</Table>
	);
}
