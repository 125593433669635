import { EventInput } from "@fullcalendar/core";
import { useQuery } from "@tanstack/react-query";
import { mongoDBAtlas } from "../../services/Api";

export function useGetRoteiros(
	idEmpresa: string,
	dataInicio: string | null,
	dataFim: string | null
) {
	const { data, isLoading } = useQuery({
		queryKey: ["getRoteiros", idEmpresa, dataInicio, dataFim],
		queryFn: async () => {
			const res = await mongoDBAtlas("POST", "roteiros", "/action/aggregate", {
				pipeline: [
					{
						$match: {
							arquivado: false,
							idEmpresa,
							data: {
								$gte: dataInicio,
								$lte: dataFim,
							},
						},
					},
					{
						$project: {
							data: 1,
							horaInicio: 1,
							horaFim: 1,
							"loja.fantasia": 1,
							"usuario.nome": 1,
							"statusRoteiro.id": 1,
						},
					},
				],
			});

			if (res?.documents) {
				const docs = res.documents.map((doc: any) => ({
					id: doc._id,
					title: doc.loja.fantasia,
					start: `${doc.data}T${doc.horaInicio}`,
					end: `${doc.data}T${doc.horaFim}`,
					extendedProps: {
						promotor: doc?.usuario?.nome,
						statusRoteiro: doc.statusRoteiro.id,
					},
					display: "block",
					backgroundColor: "#F4F4F4",
					borderColor: "#F4F4F4",
				}));
				return docs as EventInput;
			}
			return undefined;
		},
		refetchInterval: 5 * 60 * 1000, // 5 minutos em ms
		staleTime: 5 * 60 * 1000, // 5 minutos em ms,
		refetchIntervalInBackground: true,
	});

	return { data, isLoading };
}
