import { PATHS } from "./Routes";

export const flatMenuToList = (t, menus) =>
	menus.reduce(
		(acc, category) =>
			acc.concat(
				category.menus.map(menu => ({
					key: menu.nome,
					icon: menu.icon,
					value: menu.link,
					text: t(menu.nome),
				}))
			),
		[]
	);

export const filtroMenuPorTexto = (t, text) => menu =>
	menu.nome.includes(text) || t(menu.nome).toLowerCase().includes(text);

export const filtrarMenuToList = (t, modulo, pred, menus) =>
	menus.reduce((acc, category) => {
		let disponivelParaOModulo = [];
		if (modulo === 3) {
			disponivelParaOModulo = category.modulo === 3 ? category.menus : [];
		} else {
			disponivelParaOModulo =
				category.modulo === 0 || category.modulo === modulo ? category.menus : [];
		}
		return disponivelParaOModulo.reduce((acc, menu) => {
			if (pred(menu)) {
				acc.push({
					key: menu.nome,
					icon: menu.icon,
					value: menu.link,
					text: t(menu.nome),
				});
			}
			return acc;
		}, acc);
	}, []);

export const filtrarMenuToDict = (t, pred, menus) =>
	menus.reduce((acc, category) => {
		const filtrado = category.menus.filter(pred);
		if (filtrado.length > 0) {
			acc.push({
				...category,
				menus: filtrado,
			});
		}
		return acc;
	}, []);

const menus = [
	{
		titulo: "cadastros",
		menus: [
			{ nome: "roteiros", icon: "rocket", link: "/oportunidades", menu: true },
			{ nome: "treinamentos", icon: "graduation", link: "/treinamentos", menu: true },
			{ nome: "lojas", icon: "shopping cart", link: "/loja", menu: true },
			{ nome: "nova.loja", icon: "shopping cart", link: "/loja/novo", menu: false },
			{ nome: "importar.loja.do.csv", icon: "file", link: "/loja/importar", menu: false },
			{ nome: "produtos", icon: "boxes", link: "/produto", menu: true },
			{ nome: "novo.produto", icon: "boxes", link: "/produto/novo", menu: false },
			{ nome: "importar.do.csv", icon: "file", link: "/produto/importar", menu: false },
			{ nome: "novo.roteiro", icon: "map signs", link: "/roteiro/novo", menu: false },
			{ nome: "relatorios", icon: "chart bar outline", link: "/relatorios", menu: true },
		],
		modulo: 1,
	},
	{
		titulo: "agencia",
		menus: [
			{ nome: "meus.promotores", icon: "users", link: "/promotores/", menu: true },
			{
				nome: "nota.fiscal",
				icon: "file alternate outline",
				link: "/notafiscal/",
				menu: true,
			},
			{ nome: "avaliacoes", icon: "comment", link: "/avaliacoes/", menu: true },
			{ nome: "redes.de.atuacao", icon: "warehouse", link: "/redes/", menu: true },
			{ nome: "areas-de-atuacao", icon: "map", link: PATHS.areaDeAtuacao.root, menu: true },
			{ nome: "negociacoes", icon: "handshake", link: "/negociacoes/", menu: true },
		],
		modulo: 2,
	},
	{
		titulo: "oportunidades",
		menus: [{ nome: "oportunidades", icon: "search", link: "/oportunidades/", menu: true }],
		modulo: 2,
	},
	{
		titulo: "Mercado",
		menus: [{ nome: "lojas", icon: "shopping basket", link: "/lojas/", menu: true }],
		modulo: 3,
	},
];
export default menus;
