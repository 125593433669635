import _, { zipObject } from "lodash";
import firebase from "firebase";
import { db } from "../../../config";
import { isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import * as d from "../../../Utils/Debugging";
import { v4 } from "uuid";

export const formatLojas = async (nome, file, lojas) => {
	const headers = [
		"codigo",
		"fantasia",
		"razaoSocial",
		"logradouro",
		"cep",
		"numero",
		"bairro",
		"cidade",
		"estado",
		"sigla",
		"pais",
	];

	lojas = parseCSVToLojas(file, headers);
	const lojasFormatadas = await addLocalizacaoEmLojas(lojas);

	const lojasComEndereco = lojasFormatadas.filter(e => e.endereco);
	const lojasSemEndereco = lojasFormatadas.filter(e => !e.endereco);

	return {
		enviado: false,
		nome,
		lojasComEndereco,
		lojasSemEndereco,
	};
};

const parseCSVToLojas = (file, headers) => {
	return file.map(row => zipObject(headers, [...row, null, null, false]));
};

const addLocalizacaoEmLojas = async lojas => {
	const apiKey = "AIzaSyAfzb00CmRqjAdN2CHINrfzcakwi8yFvso";

	return await Promise.all(
		lojas.map(async loja => {
			const url = buildGeocodeUrl(loja, apiKey);

			try {
				const response = await fetch(url);
				const data = await response.json();
				if (data.results && data.results.length > 0) {
					const results = data.results[0];
					loja.endereco = mapGeocodeResultToEndereco(results, loja);
				} else {
					console.log(`Endereço não encontrado para loja: ${loja.fantasia}`);
				}
			} catch (error) {
				console.log("Erro ao buscar localização para a loja:", loja.fantasia, error);
			}

			return loja;
		})
	);
};

const buildGeocodeUrl = (loja, apiKey) => {
	const address = `${loja.logradouro} ${loja.bairro} ${loja.numero} ${loja.cidade} ${loja.estado} ${loja.cep} ${loja.pais}`;
	return `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;
};

const mapGeocodeResultToEndereco = (results, loja) => {
	const { GeoPoint } = firebase.firestore;

	return {
		bairro: loja.bairro,
		cidade: loja.cidade,
		coordenada: new GeoPoint(results.geometry.location.lat, results.geometry.location.lng),
		endereco: results.formatted_address,
		estado: loja.estado,
		pais: loja.pais,
		cep: loja.cep,
		logradouro: loja.logradouro,
		sigla: loja.sigla,
		numero: loja.numero,
		place_id: results.place_id,
	};
};

export const onImport = async (lojas, idEmpresa, setState) => {
	lojas.forEach(loja => {
		if (isValidCNPJ(loja.codigo)) {
			const lojasFormated = formatLojaToSave(loja, idEmpresa);

			d.log("Importar Lojas", "Importando", lojasFormated);
			setState({ carregando: true });

			try {
				db.collection("lojas").doc(loja.id).set(lojasFormated);
				setState({
					loja: {},
					carregando: false,
					salvo: true,
					erroCodigo: null,
					searchBox: null,
					enviado: true,
				});
			} catch (err) {
				console.error("Erro ao importar loja:", err.message);
				setState({
					erroCodigo: err.message,
					carregando: false,
				});
			}
		}
	});
};

const formatLojaToSave = (loja, idEmpresa) => {
	const { logradouro, bairro, numero, cidade, estado, pais, sigla, cep, cnpj, ...restValues } =
		loja;

	return {
		...restValues,
		id: v4(),
		idEmpresa: idEmpresa,
		codigo: loja.codigo.replace(/[^0-9]/g, ""),
		contato: {},
	};
};
