import parse from "csv-parse";
import firebase from "firebase";
import _, { zipObject } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion, Button, Icon, Message, Segment } from "semantic-ui-react";
import { v4 } from "uuid";
import EditButton from "../../Componentes/EditButton";
import { ModalPlanogram } from "../../Componentes/ModalPlanogram";
import { Column, DataTable } from "../../Componentes/Tabela";
import { DataTableVazia } from "../../Componentes/TabelaVazia";
import { downloadMarcasDaEmpresa } from "../../Utils/Oportunidades";
import { marcasDaEmpresa } from "../../_actions/RecursoActions";
import HeaderPadrao from "./../../Componentes/HeaderPadrao";
import { textFileReader } from "./../../Utils/File";
import NovoProdutoForm from "./NovoProdutoForm";
import { parseCsv } from "./../../Utils/Csv";

const csvExemplo = `descricao do produto; codigo de barras; precos sugerido
Exemplo de descricao do produto A.;0000+0000;20.00
Exemplo de descricao do produto B.;0000+0000;`;

const allowedProductFields = ["foto", "codigo", "descricao", "codigoBarras", "preco", "planograma"];

const fileToProduto = (nome, file) => {
	// const csv = CSV.parse(file);
	const headers = ["descricao", "codigoBarras", "preco", "planograma", "foto", "importado"];
	return {
		enviado: false,
		nome,
		produtos: file.map(row => zipObject(headers, [...row, null, null, false])),
	};
};

const DataTableHeader = props => (
	<div className="header-label" onClick={props.onClick}>
		<span>{props.label}</span>
	</div>
);

const ColunaImporado = props => {
	if (props.data.constructor === String) {
		return <Message color="red">{props.data}</Message>;
	}
	return <Icon disabled color={props.data ? "green" : "red"} name={props.data ? "check" : "x"} />;
};

const Observacao = ({ t, activeIndex, handleClick }) => {
	return (
		<Accordion>
			<Accordion.Title
				active={activeIndex === 0}
				index={0}
				onClick={handleClick}
				style={{ color: "#662780" }}>
				<Icon name="dropdown" />
				{t("observacoes")}
			</Accordion.Title>
			<Accordion.Content active={activeIndex === 0}>
				<ul>
					<li>{t("nao.utilizar.caracteres.especiais")}</li>
				</ul>
			</Accordion.Content>
		</Accordion>
	);
};

class ImportarProdutos extends Component {
	state = {
		carregando: false,
		isOpen: false,
		planogramaFile: null,
		fotoFile: null,
		produtos: [],
		nome: null,
		index: -1,
		produto: {},
		enviado: false,
		activeIndex: -1,
	};

	componentDidUpdate(prevProps) {
		this.props.idEmpresa &&
			this.props.produtos === null &&
			downloadMarcasDaEmpresa(this.props.idEmpresa).onSnapshot(snap =>
				this.props.dispatch(marcasDaEmpresa(snap))
			);
	}

	removerArquivo = (/* event */) => this.setState({ nome: null, produtos: [] });

	onSubmit = event => {
		const produtos = [...this.state.produtos];
		produtos[this.state.index] = {
			...this.state.produto,
			planogramaFile: this.state.planogramaFile,
			fotoFile: this.state.fotoFile,
			importado: false,
		};
		this.setState({
			produtos,
			index: -1,
			produto: {},
			planogramaFile: null,
			fotoFile: null,
		});
	};

	onImport = event => {
		const state = this.state;
		const props = this.props;
		const db = firebase.firestore();
		var acumulador = Promise.resolve({ batchArray: [db.batch()], results: [] });
		this.setState({ carregando: true }, () => {
			let operationCounter = 0;
			let batchIndex = 0;
			const { idEmpresa } = props;

			const stack = state.produtos.reduce((acc, item) => {
				return acc.then(({ batchArray, results }) => {
					const produto = {
						..._.pick(item, allowedProductFields),
						id: v4(),
						idEmpresa,
						arquivado: false,
					};
					// return codigoJaExiste(produtos, produto.codigo).then(() => {
					return new Promise((res, rej) => res())
						.then(() => {
							return {
								batchArray: batchArray[batchIndex].set(
									db.collection("produtos").doc(produto.id),
									produto
								),
								results: [...results, { ...produto, importado: true }],
							};
						})
						.then(res => {
							operationCounter++;
							if (operationCounter === 200) {
								batchArray.push(db.batch());
								batchIndex++;
								operationCounter = 0;
							}
							return Promise.resolve({ batchArray, results: [...res.results] });
						})
						.catch(err => {
							return Promise.resolve({
								batchArray,
								results: [...results, { ...produto, importado: false }],
							});
						});
				});
			}, acumulador);

			stack.then(({ batchArray, results }) => {
				var enviarBatch = batchArray.reduce((acc, batch) => {
					return acc.then(() => batch.commit().then(() => Promise.resolve()));
				}, Promise.resolve([]));
				enviarBatch.then(() => {
					this.setState({
						enviado: true,
						produtos: results,
						carregando: false,
					});
				});
			});
		});
	};

	onSelectFile = event => {
		var input = document.getElementById("arquivo");
		if (input.files) {
			Array.from(input.files).map(file =>
				textFileReader(file).then(({ file, data }) => {
					var regEx = new RegExp(".csv$");
					if (regEx.test(file.name)) {
						parseCsv(data, "descricao do produto", out => {
							this.setState(fileToProduto(file.name, out), () => {
								input.value = "";
							});
						});
					} else {
						alert("arquivo invalido por favor siga nosso arquivo de exemplo");
					}
				})
			);
		}
	};

	downloadExemplo = event => {
		const blob = new Blob([csvExemplo], { type: "text/csv" });
		const link = document.createElement("a");
		const linkURL = URL.createObjectURL(blob);
		link.href = linkURL;
		link.target = "_blank";
		link.download = "produtos_csv_exemplo.csv";
		link.onclick = function () {
			document.body.removeChild(link);
		};
		document.body.appendChild(link);
		link.click();
		URL.revokeObjectURL(linkURL);
	};

	selectFile = event => document.getElementById("arquivo").click();

	editItem = (index, data) => event =>
		this.setState({
			index: index,
			produto: { ...data },
		});

	cancelarEdicao = event => this.setState({ index: -1, produto: {} });

	verPlanograma = image => this.setState({ openPlanograma: image, isOpen: true });

	closePlanograma = image => this.setState({ openPlanograma: image, isOpen: false });

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	render() {
		const { t } = this.props;
		const { carregando, nome, produtos, produto, enviado } = this.state;
		if (!_.isEmpty(produto)) {
			return (
				<div>
					<HeaderPadrao
						icone="boxes"
						titulo={t("importar")}
						subtitulo={t("importar.do.csv")}
					/>
					<Segment className="purple-gradient-87">
						<NovoProdutoForm
							scope={this}
							t={t}
							carregando={carregando}
							editando={false}
							produto={produto}
							verPlanograma={this.verPlanograma.bind(this)}
							onSubmit={this.onSubmit.bind(this)}>
							<div style={{ display: "table", width: "100%" }}>
								<Button
									className="green-gradient-light"
									content={t("salvar")}
									icon="check"
									color="green"
									floated="right"></Button>
								<Button
									className="red-gradient"
									content={t("cancelar")}
									icon="times"
									color="red"
									floated="right"
									onClick={this.cancelarEdicao.bind(this)}></Button>
							</div>
						</NovoProdutoForm>
					</Segment>
					<ModalPlanogram
						isOpen={this.state.isOpen}
						image={produto.planograma}
						onClose={this.closePlanograma.bind(this)}
					/>
				</div>
			);
		}

		return (
			<div>
				<div className="importar-csv-header" style={{ marginBottom: nome ? 0 : 35 }}>
					<div>
						<HeaderPadrao
							icone="file"
							titulo={t("importar")}
							subtitulo={t("importar.do.csv")}
						/>
						<p>{t("importar.produtos.csv")}</p>
						<Observacao
							t={t}
							activeIndex={this.state.activeIndex}
							handleClick={this.handleClick}
						/>
					</div>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div
							style={{
								color: "gray",
								cursor: "pointer",
								border: "1px solid #eee",
								padding: 10,
								borderRadius: 3,
								width: "150px",
							}}
							className="dowload-exemplo-csv"
							onClick={this.downloadExemplo}>
							<i aria-hidden="true" class="download icon" />
							{t("csv.exemplo")}
						</div>
					</div>
				</div>
				{enviado ? (
					<div
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<div
							onClick={() =>
								this.setState({
									nome: null,
									enviado: false,
									produtos: [],
								})
							}
							style={{
								background: "#4CD964",
								padding: 30,
								borderRadius: 40,
								color: "#fff",
								fontSize: 25,
								marginTop: 100,
							}}>
							<Icon name="checkmark" />{" "}
							{t("produtos.importados").replace(/\{number\}/, produtos.length)}
						</div>
					</div>
				) : nome ? (
					<>
						<div className="importar-csv-header">
							<h3 style={{ display: "inline" }}>{nome}</h3>
							{carregando && (
								<span>
									<Icon size="large" loading name="spinner" />
									Importando...
								</span>
							)}
							<div style={{ display: "flex", float: "right" }}>
								<Button
									disabled={carregando}
									icon="download"
									basic
									color="green"
									onClick={this.onImport.bind(this)}
									content={t("concluir.importacao")}></Button>
								<Button
									disabled={carregando}
									icon="x"
									basic
									color="red"
									onClick={this.removerArquivo.bind(this)}
									content={t("cancelar.importacao")}></Button>
							</div>
						</div>
						<DataTable dataSource={produtos} header={DataTableHeader}>
							<Column label={t("descricao")} property="descricao" />
							<Column label={t("codigo.barras")} property="codigoBarras" />
							<Column label={t("preco.sugerido")} property="preco" />
							<Column
								label={t("importado")}
								property="importado"
								cell={ColunaImporado}
							/>
							<Column
								label={t("editar")}
								t={t}
								cell={props => (
									<EditButton
										t={props.t}
										onClick={this.editItem(props.rowIndex, props.rowData).bind(
											this
										)}
									/>
								)}
							/>
						</DataTable>
						<ModalPlanogram
							isOpen={this.state.isOpen}
							image={this.state.openPlanograma}
							onClose={this.closePlanograma.bind(this)}
						/>
					</>
				) : (
					<>
						<DataTableVazia
							selectFile={this.selectFile}
							onSelectFile={this.onSelectFile}
							t={t}
							header={DataTableHeader}
							title={t("selecionar.csv.com.produtos")}
						/>
					</>
				)}
			</div>
		);
	}
}

export default connect(state => ({
	idEmpresa: state.AutenticacaoReducer.empresa.uid,
	produtos: state.RecursosReducer.marcas,
}))(ImportarProdutos);
