import { Box, Center, Flex, Grid, GridItem, Heading, Icon, Spinner, Text } from "@chakra-ui/react";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FcBusinessman, FcClock } from "react-icons/fc";
import { useHistory } from "react-router";
import { useGetRoteiros } from "../../hooks/query/useGetRoteiros";
import { useAppSelector } from "../../hooks/useAppSelector";
import "./roteiro.css";

const roteiroStatusToColor: { [key: number]: string } = {
	1: "purple",
	2: "purple",
	3: "green",
	4: "red",
	5: "blue",
	6: "orange",
	7: "purple",
};

interface EventoRoteiroTypes {
	title: string;
	promotor: string;
	start: Date | null;
	end: Date | null;
	borderColor: string;
}
/**
 * @name EventoRoteiro
 * @date Criado em 11/09/2024
 * @author Ricardo Brito
 * @description evento do calendario de roteiros
 */
export function EventoRoteiro({ title, promotor, start, end, borderColor }: EventoRoteiroTypes) {
	return (
		<div
			style={{
				borderLeft: `3px solid ${borderColor}`,
				borderTopLeftRadius: "3px",
				borderBottomLeftRadius: "3px",
				padding: "3px 2px",
				width: "100%",
				height: "100%",
				color: "black",
			}}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}>
				<Heading size="xs" fontSize="10px" isTruncated>
					{title}
				</Heading>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginTop: "2px",
				}}>
				<Icon as={FcClock} w={4} h={4} marginRight="2px" />
				<Text fontSize="xs">
					{moment(start).format("HH:mm")} - {moment(end).format("HH:mm")}
				</Text>
			</div>
			{promotor && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}>
					<Icon as={FcBusinessman} w={4} h={4} marginRight="2px" />
					<Text fontSize="xs">{promotor}</Text>
				</div>
			)}
		</div>
	);
}
/**
 * @name CalendarioRoteiros
 * @date Criado em 11/09/2024
 * @author Ricardo Brito
 * @description Calendario de Roteiros
 */
export default function CalendarioRoteiros() {
	const empresa = useAppSelector(state => state.AutenticacaoReducer.empresa);
	const [dataInicio, setDataInicio] = useState<string | null>(null);
	const [dataFim, setDataFim] = useState<string | null>(null);
	const history = useHistory();
	const calendarRef = useRef<FullCalendar>(null);
	const [modo, setModo] = useState("dayGridMonth");
	const { data: roteiros, isLoading } = useGetRoteiros(empresa.uid, dataInicio, dataFim);
	const [mostrarLegenda, setMostrarLegenda] = useState(false);

	useEffect(() => {
		const calendarApi = calendarRef.current?.getApi();
		if (calendarApi) {
			calendarApi.changeView(modo);
		}
	}, [modo]);

	useEffect(() => {
		if (!dataInicio) {
			setDataInicio(moment().startOf("month").format("YYYY-MM-DD"));
		}
		if (!dataFim) {
			setDataFim(moment().endOf("month").format("YYYY-MM-DD"));
		}
	}, [dataInicio, dataFim]);

	/**
	 * @name removeDescricao
	 * @date Criado em 17/09/2024
	 * @author Ricardo Brito
	 * @description Remove a descrição do botão ao passar o mouse
	 */
	const removeDescricao = () => {
		const botao = document.querySelectorAll(".fc-button");
		botao.forEach(botao => {
			botao.removeAttribute("title");
		});
	};

	/**
	 * @name handleMonth
	 * @author Ricardo Brito
	 * @description muda o mes do calendario
	 */
	const handleMonth = (next?: boolean) => {
		const calendarApi = calendarRef.current?.getApi();
		if (calendarApi) {
			const modoAtual = calendarApi.view.type;
			setModo(modoAtual);
			if (next) {
				calendarApi.next();
			} else {
				calendarApi.prev();
			}
			const novaData = calendarApi.getDate();
			const date = calendarApi.getDate().getMonth();
			const month = moment().month(date);

			setDataInicio(moment(month).startOf("month").format("YYYY-MM-DD"));
			setDataFim(moment(month).endOf("month").format("YYYY-MM-DD"));

			calendarApi.gotoDate(novaData);
		}
	};

	/**
	 * @name handleEventClick
	 * @author Ricardo Brito
	 * @description Redireciona para a oportunidade
	 */
	const handleEventClick = (info: EventClickArg) => {
		const eventoId = info.event.id;
		history.push(`/oportunidade/info/${eventoId}`);
	};

	const legendas = [
		{ cor: "purple", descricao: "Aguardando Candidatos" },
		{ cor: "purple", descricao: "Pendente de Execução" },
		{ cor: "green", descricao: "Executado" },
		{ cor: "red", descricao: "Executado com Pendências" },
		{ cor: "blue", descricao: "Finalizado" },
		{ cor: "orange", descricao: "Em Execução" },
		{ cor: "purple", descricao: "Expirado" },
	];

	return isLoading ? (
		<Center h="100vh">
			<Spinner
				thickness="4px"
				speed="0.65s"
				emptyColor="gray.200"
				color="blue.500"
				size="xl"
				marginRight={15}
			/>
			<Text fontSize="2xl">Carregando...</Text>
		</Center>
	) : (
		<>
			{mostrarLegenda && (
				<Grid mt={7} justifyContent="center" templateColumns="repeat(4, 1fr)" gap={1}>
					{legendas.map((legenda, index) => (
						<GridItem key={index} w="100%" h="10">
							<Flex align="center" mb={2} maxH="10px">
								<Box
									width="16px"
									height="16px"
									bg={legenda.cor}
									borderRadius="md"
									mr={2}
								/>
								<Text fontSize="sm">{legenda.descricao}</Text>
							</Flex>
						</GridItem>
					))}
				</Grid>
			)}
			<FullCalendar
				ref={calendarRef}
				plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
				initialView={modo}
				customButtons={{
					prev: {
						text: "prev",
						click: () => handleMonth(),
					},
					next: {
						text: "next",
						click: () => handleMonth(true),
					},
					legenda: {
						text: "Legendas",
						click: () => setMostrarLegenda(prev => !prev),
					},
				}}
				noEventsContent={"Não há roteiros para o período selecionado"}
				locale="pt-br"
				buttonText={{
					today: "Hoje",
					month: "Mês",
					week: "Semana",
					day: "Dia",
					list: "Lista",
				}}
				headerToolbar={{
					left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
					center: "title",
					right: "prev,next,today legenda",
				}}
				events={roteiros as EventInput}
				initialDate={dataInicio ?? new Date()}
				eventClick={handleEventClick}
				viewDidMount={info => {
					const calendarApi = calendarRef.current?.getApi();
					const tipo = info.view.type;
					removeDescricao();
					if (["timeGridWeek", "timeGridDay", "listWeek"].includes(tipo)) {
						calendarApi?.gotoDate(moment().toDate());
					}
				}}
				eventContent={arg => {
					const statusDoRoteiro = arg.event.extendedProps.statusRoteiro;
					const borderColor = roteiroStatusToColor[statusDoRoteiro];
					return (
						<EventoRoteiro
							title={arg.event.title}
							promotor={arg.event.extendedProps.promotor}
							start={arg.event.start}
							end={arg.event.end}
							borderColor={borderColor}
						/>
					);
				}}
			/>
		</>
	);
}
