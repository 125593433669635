import { Render } from "../../../Utils/Render";
import { MostrarErro } from "../../../Utils/MostrarErro";
import { Entry } from "../../../Utils/Entry";
import { formatCNPJ } from "../../../Utils/CNPJ";
import { EmailsAdicionais } from "./EmailsAdicionais";

export const Empresa = props => {
	const {
		erros,
		carregando,
		empresa,
		t,
		validarFantasia,
		validarRazaoSocial,
		validarTelefone,
		render,
		editando,
	} = props;
	const disableFields = { disabled: carregando };

	return (
		<div class="ui grid">
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="fantasia"
					label={t("nome.fantasia")}
					text={empresa.fantasia}
					onBlur={validarFantasia}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"fantasia"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="razaoSocial"
					label={t("razao.social")}
					text={empresa.razaoSocial}
					onBlur={validarRazaoSocial}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"razaoSocial"} />
			</div>
			<div class="eight wide column">
				<Entry label={t("cnpj")} text={formatCNPJ(empresa.cnpj)} />
			</div>
			<div class="eight wide column">
				<Entry label={t("inscricao.estadual")} text={empresa.inscEstadual} />
			</div>
			<div class="eight wide column">
				<Entry label={t("email")} text={empresa.email} />
			</div>

			{editando === "view-editando" &&
				EmailsAdicionais({ ...props, render, validarTelefone, disableFields, empresa, t })}

			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="telefone"
					label={t("telefone")}
					text={empresa.telefone}
					onBlur={validarTelefone}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name="telefone" />
			</div>
		</div>
	);
};
