import { Render } from "../../../Utils/Render";
import { MostrarErro } from "../../../Utils/MostrarErro";

export const ResponsavelFinanceiro = props => {
	const { erros, carregando, empresa, t, validarEmail, render } = props;
	const disableFields = { disabled: carregando };

	return (
		<div class="ui grid">
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="nome"
					label={t("nome")}
					text={empresa.responsavelFinanceiro?.nome}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={""} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="email"
					label={t("Email")}
					text={empresa.responsavelFinanceiro?.email}
					onBlur={validarEmail}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"email"} />
			</div>
		</div>
	);
};
