import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Icon, Loader, Segment } from "semantic-ui-react";
import { ModalDeConfirmacao } from "../../Componentes/ConfirmarRegistro";
import Link from "../../Componentes/Link";
import MensagemSalvo from "../../Componentes/MensagemSalvo";
import { db } from "../../config";
import { downloadLojasDaEmpresa, downloadMarcasDaEmpresa } from "../../Utils/Oportunidades";
import { removerCandidatos } from "../../Utils/Roteiros";
import { validarRoteiro } from "../../Utils/Validacao";
import { lojasDaEmpresa, marcasDaEmpresa } from "../../_actions/RecursoActions";
import { HeaderCadastroOportunidade, salvarRoteiro } from "./NovoRoteiro";
import RoteiroConnect from "./RoteiroConnect";
import RoteiroForm from "./RoteiroForm";
import { logging } from "../../services/Api";

/**
 * Cria o contexto que passará pelo pipeline.
 */
const criarContexto = (state, props) => {
	let { salvarModelo, nomeDoModelo } = state;
	let { empresa, configuracao } = props;
	const roteiro = {
		...state.roteiroAnterior,
		...state.roteiro,
		recorrencia:
			state.recorrencia !== undefined && state.recorrencia.length > 0
				? state.recorrencia
				: null,
	};

	if (roteiro.recorrencia === null) delete roteiro.recorrencia;
	if (_.isArray(roteiro.atividades)) {
		delete roteiro.atividades;
	}

	return Promise.resolve({
		empresa,
		roteiro,
		configuracao,
		modelo: { salvarModelo, nomeDoModelo },
		validacao: null,
	});
};

/**
 * Função do pipeline que adiciona os planogramas dos produtos
 * no objeto do roteiro.
 */
const commitarRoteiro = context => {
	return salvarRoteiro(context.roteiro, context.configuracao, context.empresa).then(_roteiro => ({
		...context,
		roteiro: _roteiro,
	}));
};

export class EditarRoteiro extends Component {
	constructor(props) {
		super(props);
		let favorito = _.find(this.props.empresa.selecaoAutomatica, {
			favorito: true,
		});
		this.state = {
			carregando: false,
			roteiroAnterior: null,
			roteiro: {},
			abrirSelecaoDeProdutos: null,
			showTutorial: false,
			atividades: [],
			atividadeEditando: -1,
			tiposRoteiro: [],
			salvarModelo: false,
			nomeModelo: "",
			validacao: null,
			modelosRoteiro: [],
			errorValorMinimo: null,
			mensagemSalvando: null,
			aguardandoConfirmacao: false,
			recorrencia: [],
			idSelecaoAutomatica: favorito ? favorito.id : null,
		};
	}

	componentDidMount() {
		this.downloadRoteiro.bind(this)();
		this.props.produtos == null &&
			downloadMarcasDaEmpresa(this.props.idEmpresa).onSnapshot(snap =>
				this.props.dispatch(marcasDaEmpresa(snap))
			);
		this.props.lojas == null &&
			downloadLojasDaEmpresa(this.props.idEmpresa).onSnapshot(snap =>
				this.props.dispatch(lojasDaEmpresa(snap))
			);
	}

	componentDidUpdate() {
		!this.state.roteiroAnterior && !this.state.carregando && this.downloadRoteiro.bind(this)();
	}

	downloadRoteiro = () => {
		const onSnapshot = function (snap) {
			const roteiro = snap.data();

			this.setState({
				roteiroAnterior: roteiro,
				recorrencia: roteiro.recorrencia,
				carregando: false,
			});
		}.bind(this);
		this.setState({ carregando: true }, () =>
			db
				.collection("roteiros")
				.doc(this.props.match.params.id)
				.get()
				.then(snap => {
					const roteiro = snap.data();
					if (
						roteiro.statusRoteiro.id === 1 &&
						roteiro.idEmpresa === this.props.idEmpresa
					) {
						return onSnapshot(snap);
					} else {
						return this.props.history.push("/dashboard");
					}
				})
				.catch(() => this.setState({ carregando: false }))
		);
	};

	/**
	 * Renahn Schultz
	 *
	 * Altera o estado do componente referente.
	 */
	handleChange = event => this.setState({ [event.target.name]: event.target.value });

	/**
	 * Atualiza a propriedade do objeto roteiro no state.
	 * @param {string} name Nome da propriedade.
	 * @param {any} value O valor a ser definido.
	 */
	handleUpdateRoteiro = (name, value) =>
		this.setState({ roteiro: { ...this.state.roteiro, [name]: value } });

	/**
	 * Calback para inputs de texto, data...
	 */
	handleChangeRoteiro = event => this.handleUpdateRoteiro(event.target.name, event.target.value);

	/**
	 * Callback para atualização do valor do roteiro.
	 */
	handleChangeValorRoteiro = (e, { name, value }) => this.handleUpdateRoteiro(name, value);

	/**
	 * Callback para atualização do qual a loja e tipo de atividade.
	 * @param {string} name Nome do campo que será alterado.
	 */
	handleChangeSelectItem =
		name =>
		(e, { value }) =>
			this.handleUpdateRoteiro(name, value);

	/**
	 * Callback para seleção de loja.
	 */
	handleChangeSelectLoja = this.handleChangeSelectItem("loja");

	/**
	 * Callback para seleção do tipo de atividade.
	 */
	handleChangeSelectTipo = this.handleChangeSelectItem("tipo");

	/**
	 * Callback para atualizar a seleção de nota fiscal ou não.
	 */
	handleChangeNotaFiscal = (event, { name, value }) =>
		this.handleUpdateRoteiro("notaFiscal", { requerida: value });

	/**
	 * Callback que altera a descrição geral do roteiro.
	 */
	handleChangeDescricaoGeral = event =>
		this.setState({
			roteiro: {
				...this.state.roteiro,
				descricao: event.target.value,
			},
		});

	// Callback para gereciar o estado das epis
	handleCheckBoxEpis = epi => {
		var array = this.state.roteiro.epis || [];
		if (array.includes(epi)) {
			var filtered = array.filter(value => {
				return value !== epi;
			});
			this.setState({ roteiro: { ...this.state.roteiro, epis: filtered } });
		} else
			this.setState({
				roteiro: { ...this.state.roteiro, epis: [...array, epi] },
			});
	};

	handleDeleteSecao = (index, e, roteiro) => {
		e.preventDefault();
		e.stopPropagation();
		let roteiroFon = roteiro;
		delete roteiroFon.secoes[index];
		this.setState({ roteiro: roteiroFon });
		return roteiro;
	};

	handleDeleteTreinamento = (index, e, roteiro) => {
		e.preventDefault();
		e.stopPropagation();
		let roteiroFon = roteiro;
		delete roteiroFon.treinamentos[index];
		this.setState({ roteiro: roteiroFon });
		return roteiro;
	};

	/**
	 * Requisita validação do roteiro.
	 */
	validarRoteiro = (props, state) => {
		let rec = this.state.recorrencia;
		let recorrencia =
			rec !== undefined &&
			rec.length > 0 &&
			this.state.roteiro.data !== this.state.roteiro.dataFim
				? rec
				: null;

		const roteiro = {
			...state.roteiroAnterior,
			...state.roteiro,
			recorrencia,
		};

		if (recorrencia === null) delete roteiro.recorrencia;
		if (_.isArray(roteiro.atividades)) {
			delete roteiro.atividades;
		}

		return validarRoteiro(roteiro, props);
	};

	dismissMensagemSalvo = () =>
		this.props.history.push(`/oportunidade/info/${this.props.match.params.id}`);

	updateMessage = (msg, context) =>
		new Promise(s =>
			this.setState(
				{
					mensagemSalvando: msg,
				},
				() => s(context)
			)
		);

	/**
	 * Verificar os dias da semana marcados para a recorrência
	 */
	diaSemana = (dia, e) => {
		e.preventDefault();
		const { recorrencia } = this.state;
		var set = new Set(recorrencia);
		recorrencia.includes(dia) ? set.delete(dia) : set.add(dia);
		this.setState({ recorrencia: Array.from(set) });
	};

	/**
	 * @name handleSelecaoAutomatica
	 * @description pega o id do evento que vem
	 * e seta na selecao automatica de empresas favoritas
	 * @param {Object} e evento
	 */
	handleSelecaoAutomatica = e => {
		if (e.currentTarget.id !== this.state.idSelecaoAutomatica) {
			this.setState({ idSelecaoAutomatica: e.currentTarget.id });
		} else {
			if (this.state.roteiro.idSelecaoAutomatica) {
				let roteiro = this.state.roteiro;
				delete roteiro.idSelecaoAutomatica;
				this.setState({ idSelecaoAutomatica: null, roteiro: { ...roteiro } });
			} else {
				this.setState({ idSelecaoAutomatica: null });
			}
		}
	};

	/**
	 * Chamado após a confirmação.
	 */
	respostaConfirmacao = () => {
		const scope = this;
		this.setState({ aguardandoConfirmacao: false, salvando: true, validacao: null }, () =>
			criarContexto(this.state, this.props)
				.then(context => {
					return scope
						.updateMessage("aguarde.registrando.roteiro", context)
						.then(commitarRoteiro)
						.then(context => {
							logging("UPDATE", "log.update.roteiro", this.props.match.params.id);
							return context;
						});
				})
				.then(context => removerCandidatos(context.roteiro.id).then(() => context))
				.then(context => {
					scope.setState({
						validacao: null,
						salvarModelo: false,
						nomeDoModelo: "",
						salvando: false,
						mensagemSalvo: true,
						mensagemSalvando: null,
						atividades: {},
						abrirSelecaoDeProdutos: null,
						atividadeEditando: -1,
						errorValorMinimo: null,
					});
					return context;
				})
				.catch(context => {
					if (_.isString(context)) {
						return console.error(context);
					}
					if (context.validacao.status === "error/cancelado") {
						scope.setState({
							validacao: scope.props.t(context.validacao.message),
							salvando: false,
							mensagemSalvo: false,
							mensagemSalvando: null,
						});
					}
				})
				.catch(console.error)
		);
	};

	/**
	 * @name handleCheckBoxSelecaoAutomatica
	 * @description coloca para se caso o cliente
	 * quiser colocar a atividade como SelecaoAutomatica
	 */

	handleChangeRadio = (e, { value }) => {
		if (value === "automatico") {
			this.setState({
				roteiro: {
					...this.state.roteiro,
					selecaoAutomatica: true,
					selecaoAutomaticaFavorito: false,
				},
			});
		} else {
			this.setState({
				roteiro: {
					...this.state.roteiro,
					selecaoAutomatica: false,
					selecaoAutomaticaFavorito: true,
				},
			});
		}
	};

	defaultChecked = () => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				selecaoAutomatica: true,
				selecaoAutomaticaFavorito: false,
			},
		});
	};

	toggleRadios = () => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				selecaoAutomatica: false,
				selecaoAutomaticaFavorito: false,
			},
		});
	};

	onSubmitRoteiro = () => {
		const validacao = this.validarRoteiro(this.props, this.state);

		if (validacao) {
			return this.setState({
				validacao,
				salvando: false,
				mensagemSalvo: false,
				mensagemSalvando: null,
			});
		}

		const contaBloqueada = bloquearCadastro =>
			bloquearCadastro
				? Promise.reject({
						validacao: {
							status: "error/bloqueado",
							message: "conta bloqueada",
						},
					})
				: Promise.resolve();

		contaBloqueada(this.props.bloquearCadastro).then(() =>
			this.setState({ aguardandoConfirmacao: true, validacao: null })
		);
	};

	salvarTreinamentos = treinamentos => {
		this.setState({ roteiro: { ...this.state.roteiro, treinamentos } }, () =>
			console.log(this.state.roteiro)
		);
	};

	salvarSecao = secao => {
		const _secoes = this.state.roteiro.secoes ?? this.state.roteiroAnterior.secoes ?? {};
		_secoes[secao.id] = secao;
		this.setState({ roteiro: { ...this.state.roteiroAnterior, secoes: _secoes } }, () =>
			console.log(this.state.roteiro)
		);
	};

	handleChangeRadioObrigatorio = (e, { value }) => {
		this.setState({
			roteiro: {
				...this.state.roteiro,
				tempoEstimadoObrigatorio: !value,
			},
		});
	};

	render() {
		const { t, faturamentoMensal } = this.props;
		const { salvando, mensagemSalvo, recorrencia, validacao, idSelecaoAutomatica } = this.state;
		if (!this.state.roteiroAnterior) {
			return (
				<Segment basic className="page-loading">
					<Loader style={{ display: "block" }} inline="centered">
						{t("carregando")}
					</Loader>
				</Segment>
			);
		}

		const lojas = (_.isArray(this.props.lojas) && this.props.lojas) || [];

		const roteiro = {
			...this.state.roteiroAnterior,
			...this.state.roteiro,
		};

		if (salvando) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "80%" }}>
					<HeaderCadastroOportunidade t={t} />
					<Segment inverted color="orange">
						<div style={{ height: "60vh" }}>
							<Loader
								className="roteiro-loader"
								size="massive"
								active
								indeterminate
								inverted>
								{t(this.state.mensagemSalvando)}
							</Loader>
						</div>
					</Segment>
				</div>
			);
		}

		if (mensagemSalvo) {
			return (
				<div className="novo-roteiro" style={{ margin: "0 auto", width: "80%" }}>
					<HeaderCadastroOportunidade t={t} />
					<MensagemSalvo novoRegistro={this.dismissMensagemSalvo} t={t} />
				</div>
			);
		}
		return (
			<div className="novo-roteiro" style={{ margin: "0 auto" }}>
				<div style={{ margin: "20px 0 40px", textAlign: "center" }}>
					<h1
						style={{
							margin: "0",
							fontSize: "28px",
							fontWeight: "bold",
							color: "#3f2185",
						}}>
						{t("editar.oportunidade")}
					</h1>
				</div>
				<Form onSubmit={this.onSubmitRoteiro.bind(this)}>
					<RoteiroForm
						t={t}
						validacao={validacao}
						roteiro={roteiro}
						lojas={lojas}
						recorrencia={recorrencia}
						faturamentoMensal={faturamentoMensal}
						idSelecaoAutomatica={idSelecaoAutomatica}
						diaSemana={this.diaSemana}
						salvarTreinamentos={this.salvarTreinamentos}
						salvarSecao={this.salvarSecao}
						handleDeleteSecao={this.handleDeleteSecao}
						handleChangeRadio={this.handleChangeRadio}
						toggleRadios={this.toggleRadios}
						defaultChecked={this.defaultChecked}
						handleDeleteTreinamento={this.handleDeleteTreinamento}
						handleChangeRoteiro={this.handleChangeRoteiro.bind(this)}
						handleChangeSelectTipo={this.handleChangeSelectTipo.bind(this)}
						handleChangeSelectLoja={this.handleChangeSelectLoja.bind(this)}
						handleChangeDescricaoGeral={this.handleChangeDescricaoGeral.bind(this)}
						handleChangeValorRoteiro={this.handleChangeValorRoteiro.bind(this)}
						handleChangeNotaFiscal={this.handleChangeNotaFiscal.bind(this)}
						handleChangeSelecaoAutomatica={this.handleSelecaoAutomatica.bind(this)}
						handleCheckBoxEpis={this.handleCheckBoxEpis}
						handleChangeRadioObrigatorio={this.handleChangeRadioObrigatorio}
					/>
					<div
						style={{
							display: "flex",
							marginTop: "25px",
							justifyContent: "flex-end",
						}}>
						<button
							type="button"
							className="ui basic icon right button"
							onClick={() => this.setState({ showTutorial: true })}>
							<Icon name="help" />
						</button>
						<Button
							as={Link}
							to={`/oportunidade/info/${this.props.match.params.id}`}
							className="green-gradient-light"
							content={t("cancelar")}
							icon="left arrow"
							color="red"></Button>
						<Button
							className="green-gradient-light"
							content={t("salvar")}
							disabled={false}
							icon="check"
							color="green"></Button>
					</div>
				</Form>
				<ModalDeConfirmacao
					t={t}
					isOpen={this.state.aguardandoConfirmacao}
					titulo={t("edicao.de.oportunidade")}
					message={
						<p style={{ marginTop: 10 }}>
							{this.props.t("edicao.de.oportunidade.mensagem")}
						</p>
					}
					onConfirm={() =>
						this.setState(
							{ aguardandoConfirmacao: false },
							this.respostaConfirmacao.bind(this)
						)
					}
					onCancel={() => this.setState({ aguardandoConfirmacao: false })}
				/>
			</div>
		);
	}
}

export default RoteiroConnect(withTranslation()(EditarRoteiro));
