import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Collapse,
	Divider,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Rating } from "semantic-ui-react";
import { MarkOptional } from "ts-essentials";
import { z } from "zod";
import { Evaluation } from "../../@types/Evaluation";
import { logging } from "../../services/Api";
import { EvaluationService } from "../../services/firebase/Evaluation";
import { TextAreaForm } from "../Form/chakraUI/TextAreaForm";
import { Iconify } from "../Iconify";

interface ModalEvaluationPromoterProps {
	setEvaluation: Dispatch<SetStateAction<Evaluation>>;
	evaluation: MarkOptional<Evaluation, "id">;
	complementaryData: Pick<EvaluationFormProps, "promoterOrAgencyId" | "agency">;
	wasEvaluated: boolean;
}

const maxTextAreaLimit = 450;

const EVALUATION_FORM_SCHEMA = z
	.object({
		rating: z.number().min(1).max(5) as z.ZodSchema<1 | 2 | 3 | 4 | 5>,
		description: z
			.string()
			.trim()
			.min(0)
			.max(
				maxTextAreaLimit,
				`A descrição não pode passar de ${maxTextAreaLimit} caracteres.`
			),
		opportunityId: z.string().min(1),
		promoterOrAgencyId: z.string().min(1),
		agency: z.object({ promoterId: z.string().min(1) }).optional(),
		companyName: z.string().min(1),
		companyId: z.string().min(1),
	})
	.superRefine((value, ctx) => {
		if (value.rating <= 2 && value.description.length === 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Quando sua avaliação é menor que 3, a descrição é obrigatória.",
				path: ["description"],
			});
		}
	});

type EvaluationFormProps = z.infer<typeof EVALUATION_FORM_SCHEMA>;

export function ModalEvaluationPromoter({
	setEvaluation,
	wasEvaluated,
	evaluation,
	complementaryData,
}: ModalEvaluationPromoterProps) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const modalSize = useBreakpointValue({ base: "full", sm: "2xl" });
	const { t } = useTranslation();

	const {
		watch,
		handleSubmit,
		reset: resetForm,
		control: formController,
		formState: { isSubmitting },
	} = useForm<EvaluationFormProps>({
		resolver: zodResolver(EVALUATION_FORM_SCHEMA),
		defaultValues: {
			rating: evaluation.avaliacao,
			agency: complementaryData.agency,
			companyId: evaluation.idOportunidade,
			companyName: evaluation.empresa.fantasia,
			description: evaluation.comentario || "",
			opportunityId: evaluation.idOportunidade,
			promoterOrAgencyId: complementaryData.promoterOrAgencyId,
		},
	});

	const ratingController = useController({ name: "rating", control: formController });

	const onSubmit: SubmitHandler<EvaluationFormProps> = async data => {
		try {
			const savedEvaluation = await EvaluationService.getInstance().evaluate({
				basicEvaluationData: {
					avaliacao: data.rating,
					comentario: data.description,
					data: new Date().toISOString(),
					id: evaluation?.id || undefined,
					idOportunidade: data.opportunityId,
					empresa: {
						id: data.companyId,
						fantasia: data.companyName,
					},
				},
				agency: data.agency,
				opportunityId: data.opportunityId,
				promoterOrAgencyId: data.promoterOrAgencyId,
			});
			setEvaluation(savedEvaluation);
			if (evaluation?.id) {
				logging(
					"UPDATE",
					"log.update.avaliacao.oportunidade",
					`Roteiro: ${data.opportunityId}, Promotor: ${data.promoterOrAgencyId}`
				);
			} else {
				logging(
					"CREATE",
					"log.avaliar.oportunidade",
					`Roteiro: ${data.opportunityId}, Promotor: ${data.promoterOrAgencyId}`
				);
			}

			toast.success(t("avaliacao-enviada-com-sucesso"));

			onClose();
			resetForm(data);
		} catch (error) {
			console.error(error);
			toast.error(t("erro-ao-avaliar-promotor-tente-novamente-mais-tarde"));
		}
	};

	useEffect(() => {
		if (!isOpen) {
			resetForm();
		}
	}, [isOpen, resetForm]);

	const descriptionValue = watch("description");
	const isEvaluationNoteVerySmall = ratingController.field.value <= 2;
	const isCollapseOpen = descriptionValue.length === 0 && isEvaluationNoteVerySmall;

	return (
		<>
			<Button
				onClick={onOpen}
				leftIcon={
					<Iconify icon={wasEvaluated ? "mdi:check-decagram" : "mdi:star"} inline />
				}
				w="full"
				colorScheme={wasEvaluated ? "green" : "purple"}>
				{wasEvaluated ? t("ver-avaliacao") : t("avaliar")}
			</Button>
			<Modal isOpen={isSubmitting || isOpen} onClose={onClose} isCentered size={modalSize}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t("avaliar-promotor")}</ModalHeader>
					<ModalCloseButton />
					<Divider />
					<ModalBody
						id="evaluation-promoter-form"
						my={5}
						textAlign={"center"}
						as="form"
						onSubmit={handleSubmit(onSubmit)}>
						<Flex flexDir={"column"} gap={5}>
							<Box>
								<Heading size={"md"}>{t("como-voce-avalia-o-promotor")}</Heading>
								<Text color={"gray.500"} fontSize={"md"}>
									{t(
										"descreva-em-poucas-palavras-suas-experiencia-com-essa-oportunidade"
									)}
								</Text>
							</Box>
							<Box>
								<Rating
									maxRating={5}
									ref={ratingController.field.ref}
									onRate={(_, { rating }) =>
										ratingController.field.onChange(rating)
									}
									rating={ratingController.field.value}
									size="massive"
									icon="star"
								/>
							</Box>
							<Box>
								<TextAreaForm
									isRequired={isEvaluationNoteVerySmall}
									placeholder={t("placeholde-textarea-avaliacao-promotor")}
									minH={"100"}
									name="description"
									control={formController}
									label={t("descricao")}
									maxLength={maxTextAreaLimit}
								/>
								<Text fontSize={"sm"} color={"gray.500"} textAlign={"right"}>
									{descriptionValue.length}/{maxTextAreaLimit}
								</Text>
							</Box>
						</Flex>
						<Collapse in={isCollapseOpen}>
							<Alert status="info" rounded={"md"} textAlign="left">
								<AlertIcon />
								<Box>
									<AlertTitle>{t("titulo-aviso-avaliacao-baixa")}</AlertTitle>
									<AlertDescription>
										{t("descricao-aviso-avaliacao-baixa")}
									</AlertDescription>
								</Box>
							</Alert>
						</Collapse>
					</ModalBody>
					<Divider />
					<Flex justifyContent={"flex-end"} gap={2} m={4}>
						<Button
							colorScheme="red"
							onClick={onClose}
							variant="outline"
							isDisabled={isSubmitting}>
							{t("cancelar")}
						</Button>
						<Button
							colorScheme={"green"}
							type="submit"
							form="evaluation-promoter-form"
							isLoading={isSubmitting}>
							{t("avaliar")}
						</Button>
					</Flex>
				</ModalContent>
			</Modal>
		</>
	);
}
