import { Center } from "@chakra-ui/react";
import { createBrowserHistory } from "history";
import { isEmpty } from "lodash";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, Route, Router, Switch } from "react-router-dom";
import ReactTypingEffect from "react-typing-effect";
import { Button, Header } from "semantic-ui-react";
import Avaliacoes from "./Agencia/Avaliacoes/Avaliacoes";
import DashboardAgencia from "./Agencia/Dashboard/Dashboard";
import DirecionarOportunidades from "./Agencia/Oportunidades/DirecionarOportunidades";
import InformacoesOportunidade from "./Agencia/Oportunidades/InformacoesOportunidade/index";
import Oportunidades from "./Agencia/Oportunidades/Oportunidades";
import Promotores from "./Agencia/Promotores/Promotores";
import { SnackbarProv } from "./Componentes/Componentes";
import Loading from "./Componentes/Loading";
import { ModalBloqueioSistema } from "./Componentes/ModalBloqueioSistema";
import { db } from "./config";
import logo from "./imagens/logo-icone.svg";
import logoFooterFreelancer from "./imagens/logo-nome.svg";
import Dashboard from "./Interno/Dashboard/Dashboard";
import Feedback from "./Interno/Feedback/Feedback";
import DetalhesFatura from "./Interno/Financeiro/DetalhesFatura";
import Financeiro from "./Interno/Financeiro/Financeiro";
import SaldoDevedor from "./Interno/Financeiro/SaldoDevedor";
import importarProdutosTriunfante from "./Interno/importarProdutos/importarProdutos";
import EditarLoja from "./Interno/Loja/EditarLoja";
import Loja from "./Interno/Loja/Loja";
import NovaLoja from "./Interno/Loja/NovaLoja";
import Perfil from "./Interno/Perfil/Perfil";
import SelecaoAutomatica from "./Interno/Perfil/SelecaoAutomatica";
import UserProfile from "./Interno/Perfil/UserProfile/index";
import EditarProduto from "./Interno/Produto/EditarProduto";
import ImportarProduto from "./Interno/Produto/ImportarProdutos";
import NovoProduto from "./Interno/Produto/NovoProduto";
import Produto from "./Interno/Produto/Produto";
import RelatorioBase from "./Interno/Relatorios/RelatorioBase";
import EditarRoteiro from "./Interno/Roteiro/EditarRoteiro";
import NovoRoteiro from "./Interno/Roteiro/NovoRoteiro";
import CadastroRecorrencia from "./Interno/Roteiro/Recorrencia/CadastroRecorrencia";
import RoteiroBase from "./Interno/Roteiro/roteiroBase";
import Roteiros from "./Interno/Roteiro/Roteiros";
import Template from "./Interno/Template/Template";
import NovoTreinamento from "./Interno/Treinamento/NovoTreinamento";
import TreinamentoBase from "./Interno/Treinamento/TreinamentoBase";
import Treinamentos from "./Interno/Treinamento/Treinamentos";
import CadastroEmpresa from "./Login/Cadastro/CadastroEmpresa";
import DashboardMercado from "./Mercado/DashboardMercado";
import LojaMercado from "./Mercado/Loja";
import Lojas from "./Mercado/Lojas";
import ImportarLoja from "./Interno/Loja/ImportarLoja/ImportarLoja";
import AgencyReceipt from "./pages/AgencyReceipt";
import LogList from "./pages/LogList/index.tsx";
import Negotiations from "./pages/Negotiations";
import { OperationArea } from "./pages/OperationArea";
import { Redes } from "./pages/Redes";
import { UnauthenticatedPages } from "./pages/Unauthenticated";
import UsersPage from "./pages/UsersPage";
import UsuarioBloqueado from "./UsuarioBloqueado";
import { PATHS } from "./Utils/Routes";
import { atualizarFatura, salvarCoordenadas } from "./_actions/AutenticacaoActions";
import { LOGADO } from "./_actions/Types";

const history = createBrowserHistory();
// const unlisten = history.listen((location, action) => {
//     window.scroll(0, 0);
// });

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				rest.autenticado !== LOGADO ? (
					<Redirect to={{ pathname: "/login" }} />
				) : !isEmpty(rest.empresa.bloqueio) ? (
					<Redirect to={{ pathname: "/bloqueio" }} />
				) : rest.fatura.pago === false &&
				  moment().isAfter(moment(rest.fatura.boleto.due_date).add(2, "day")) ? (
					<SaldoDevedor
						vencimento={rest.fatura.boleto.due_date}
						empresa={rest.empresa}
						history={props.history}
					/>
				) : (
					<Template
						{...props}
						component={Component}
						location={props.match.path}
						{...rest.extra}></Template>
				)
			}
		/>
	);
};

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			props.location.pathname !== "/sorteio" && rest.autenticado === LOGADO ? (
				<Redirect to={{ pathname: "/dashboard" }} />
			) : (
				<Component {...props} />
			)
		}
	/>
);

const NotFound = () => {
	return (
		<div className="not-found">
			<Header className="purple-gradiente-45">
				<h1>404</h1>
				<p>Página não encontrada.</p>
			</Header>
			<p>
				<Link to="/dashboard" style={{ color: "#b33dff" }}>
					<Button color="violet" basic to="/dashboard">
						<Link to="/dashboard" style={{ color: "#b33dff" }}>
							Voltar ao inicio
						</Link>
					</Button>
				</Link>
			</p>
			<Center>
				<img
					src={logoFooterFreelancer}
					width="194"
					height="44"
					alt="Promotor"
					className="img-fluid mt-5 mb-5"
				/>
			</Center>
		</div>
	);
};

//endereço, component, modulo, extra
const privateTable = [
	["/dashboard", Dashboard, 1, { fluid: true }],
	["/logs", LogList, 0],
	["/loja", Loja, 1],
	["/loja/novo/:pdv", NovaLoja, 1],
	["/loja/novo", NovaLoja, 1],
	["/loja/importar", ImportarLoja, 1],
	["/loja/:id", EditarLoja, 1],
	["/produto", Produto, 1],
	["/produto/novo", NovoProduto, 1],
	["/produto/importar", ImportarProduto, 1],
	["/produto/importarTriunfante", importarProdutosTriunfante, 0],
	["/produto/:id", EditarProduto, 1],
	["/treinamento/novo", NovoTreinamento, 1],
	["/treinamento/:id", TreinamentoBase, 1],
	["/treinamentos", Treinamentos, 1],
	["/oportunidades", Roteiros, 1],
	["/oportunidades/:aba", Roteiros, 1],
	["/oportunidade/info/:id", RoteiroBase, 1, { fluid: true }],
	["/roteiro/novo/:lojaId", NovoRoteiro, 1],
	["/roteiro/novo/", NovoRoteiro, 1],
	["/recorrencia/novo", CadastroRecorrencia, 1],
	["/recorrencia/novo/:id", CadastroRecorrencia, 1],
	["/roteiro/editar/:id", EditarRoteiro, 0],
	["/relatorios", RelatorioBase, 0],
	["/loading", Loading, 0, { fluid: true }],

	// ['/perfil/:id', PerfilUsuario, 0, { fluid: true }],
	["/perfil/:id", UserProfile, 0, { fluid: true }],
	["/perfil", Perfil, 0],
	["/selecaoAutomatica", SelecaoAutomatica, 0],
	[PATHS.usuarios.root, UsersPage, 0],
	["/feedback", Feedback, 0],
	["/financeiro", Financeiro, 0],
	["/financeiro/:aba", Financeiro, 0],
	["/fatura/:id", DetalhesFatura, 0],

	["/dashboard", DashboardMercado, 3],
	["/lojas", Lojas, 3],
	["/Loja/:id", LojaMercado, 3],
	["/perfil", DashboardMercado, 3],

	["/dashboard", DashboardAgencia, 2, { fluid: true }],
	["/oportunidades", Oportunidades, 2],
	["/oportunidades/:aba", Oportunidades, 2],
	["/direcionar", DirecionarOportunidades, 2],
	["/promotores", Promotores, 2],
	["/buscar/:id", InformacoesOportunidade, 2, { fluid: true }],
	["/direcionar/:id", InformacoesOportunidade, 2, { fluid: true }],
	["/notafiscal", AgencyReceipt, 2],
	["/avaliacoes", Avaliacoes, 2],
	[PATHS.redes.root, Redes.List, 2],
	[PATHS.areaDeAtuacao.root, OperationArea.List, 2],
	[PATHS.areaDeAtuacao.create, OperationArea.Create, 2],
	[PATHS.areaDeAtuacao.editar(":id"), OperationArea.Edit, 2],
	["/negociacoes", Negotiations, 2],
];

const unauthenticatedTable = [
	[PATHS.unauthenticated.register, CadastroEmpresa],
	[PATHS.unauthenticated.login, UnauthenticatedPages.Login],
	[PATHS.unauthenticated.forgotPassword, UnauthenticatedPages.ForgotPassword],
];

class Rotas extends Component {
	state = {
		fatura: null,
		localizacaoBloqueada: false,
	};

	buscarCoordenadas = () => {
		const geoLocationOptions = {
			enableHighAccuracy: true,
			maximumAge: 1000,
			timeout: 1000,
		};

		navigator.geolocation.getCurrentPosition(
			position => {
				this.props.salvarCoordenadas({
					lat: position?.coords?.latitude,
					lng: position?.coords?.longitude,
				});
			},
			() => {},
			geoLocationOptions
		);
	};

	adicionarOuvintePermissoes = () => {
		navigator.permissions.query({ name: "geolocation" }).then(permissionStatus => {
			permissionStatus.onchange = () => {
				this.setState({
					localizacaoBloqueada: permissionStatus.state === "granted" ? false : true,
				});
				permissionStatus.onchange = null;
			};
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.empresa !== this.props.empresa && this.props.usuario) this.listarFaturas();
	}

	listarFaturas = async () => {
		const snap = await db
			.collection("empresas")
			.doc(this.props.empresa.uid)
			.collection("faturas")
			.orderBy("data", "desc")
			.limit(1)
			.get();
		if (snap.size === 1) {
			let fatura = snap.docs[0].data();
			this.setState({ fatura });
			this.props.atualizarFatura(fatura);
		} else {
			let fatura = [];
			this.setState({ fatura });
			this.props.atualizarFatura(fatura);
		}
	};

	render() {
		return !this.props.firebaseReady ||
			(this.props.usuario && !this.props.empresa && !this.props.mercado) ||
			(this.props.usuario && !this.state.fatura) ? (
			<div className="not-found">
				<div className="logo-loading-container">
					<div className="loading-container logo-loading2"></div>
					<div className="logo-loading loading-container"></div>
					<img
						className="logo-loading-img"
						alt="Logo Promotor Freelance"
						src={logo}
						width="54"
					/>
				</div>
				<br />
				<br />
				<span className="loading-text">
					<ReactTypingEffect
						speed={10}
						eraseDelay={2000}
						typingDelay={300}
						text={[
							"Buscando informações da empresa...",
							"Configurando o sistema...",
							"Validando informações ...",
							"Carregando candidatos...",
						]}
					/>
				</span>
			</div>
		) : (
			<Router history={history}>
				{this?.props?.autenticado === "LOGADO" &&
					(this.buscarCoordenadas(), this.adicionarOuvintePermissoes())}
				{this.state.localizacaoBloqueada && (
					<ModalBloqueioSistema
						aberto={this.state.localizacaoBloqueada}
						setAberto={() => {}}
						titulo="Permissão de localização"
						mensagem="Para o funcionamento do sistema é necessário a sua localização, por favor, permita a localização na barra superior ou nas configurações do navegador e recarregue a página."
					/>
				)}
				<div style={{ minHeight: "100%" }}>
					<SnackbarProv>
						<Switch>
							<Route path="/" exact {...this.props}>
								<Redirect to="/login" />
							</Route>
							<Route
								path="/contato"
								exact
								{...this.props}
								component={() => {
									window.location.href =
										"https://www.promotorfreelance.com.br/contato/";
								}}
							/>
							<Route
								path="/bloqueio"
								exact
								{...this.props}
								component={() => (
									<UsuarioBloqueado data={this?.props?.empresa?.bloqueio} />
								)}
							/>
							{unauthenticatedTable.map((route, i) => (
								<UnauthenticatedRoute
									key={i}
									path={route[0]}
									{...this.props}
									component={route[1]}
								/>
							))}
							{privateTable.map((route, i) => {
								if (this.props.modulo === 3) {
									if (route[2] === 3) {
										return (
											<PrivateRoute
												fatura={this.state.fatura}
												key={i}
												path={route[0]}
												exact
												{...this.props}
												component={route[1]}
												extra={route[3]}
											/>
										);
									}
								} else {
									if (route[2] === this.props.modulo || route[2] === 0) {
										return (
											<PrivateRoute
												fatura={this.state.fatura}
												key={i}
												path={route[0]}
												exact
												{...this.props}
												component={route[1]}
												extra={route[3]}
											/>
										);
									}
								}
								return null;
							})}
							<Route component={NotFound} />
						</Switch>
					</SnackbarProv>
				</div>
			</Router>
		);
	}
}
const mapStateToProps = state => {
	return {
		usuario: state.AutenticacaoReducer.usuario,
		mercado: state.AutenticacaoReducer.mercado,
		empresa: state.AutenticacaoReducer.empresa,
		modulo: state.AutenticacaoReducer.modulo,
	};
};

export default connect(mapStateToProps, { atualizarFatura, salvarCoordenadas })(Rotas);
