import { Render } from "../../../Utils/Render";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MostrarErro } from "../../../Utils/MostrarErro";

export const Endereco = props => {
	const { erros, carregando, carregandoCEP, empresa, t, render, buscarCep } = props;
	const disableFields = { disabled: carregando };

	return (
		<div class="ui grid">
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="cep"
					label={t("cep")}
					text={empresa.endereco.cep}
					onBlur={buscarCep}
					{...disableFields}
				/>
				{carregandoCEP && (
					<div className="form-group mt-0 d-flex flex-column">
						<LinearProgress />
					</div>
				)}
				<MostrarErro erros={erros} name={"cep"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="logradouro"
					label={t("logradouro")}
					text={empresa.endereco.logradouro}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"logradouro"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					required
					name="numero"
					label={t("numero")}
					text={empresa.endereco.numero}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"numero"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					name="complemento"
					label={t("complemento")}
					text={empresa.endereco.complemento}
					{...disableFields}
				/>
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					name="bairro"
					required
					label={t("bairro")}
					text={empresa.endereco.bairro}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"bairro"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					name="cidade"
					required
					label={t("cidade")}
					text={empresa.endereco.cidade}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"cidade"} />
			</div>
			<div class="eight wide column">
				<Render
					{...props}
					render={render}
					name="estado"
					required
					label={t("estado")}
					text={empresa.endereco.estado}
					{...disableFields}
				/>
				<MostrarErro erros={erros} name={"estado"} />
			</div>
		</div>
	);
};
