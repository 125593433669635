import { Button, Icon } from "semantic-ui-react";
import { useState } from "react";
import { Render } from "../../../Utils/Render";

export const EmailsAdicionais = props => {
	let value = props.empresa?.emailsAdicionais ?? {};
	const [emails, setEmail] = useState(value);
	const maior = _.max(_.map(emails, (e, key) => parseInt(key.split("-")[1])));

	const adicionar = () => {
		return (
			<center>
				{chaves.length ? null : (
					<center>
						<label
							style={{
								fontWeight: props.render.name !== "Entry" ? "initial" : "bold",
							}}>
							{props.t("emails.Adicionais")}
						</label>
						<p style={{ lineHeight: 0, color: "rgba(0,0,0,0.6)" }}>
							{props.t("adicione.emails.adicionais")}
						</p>
						<br />
					</center>
				)}
				<Button
					onClick={e => {
						e.preventDefault();
						setEmail({ ...emails, [`email-${(maior ?? 0) + 1}`]: "" });
					}}>
					<Icon name="plus" /> Email Adicional
				</Button>
			</center>
		);
	};

	const removerEmail = email => {
		let em = emails;
		delete em[email];
		setEmail(em);
	};

	const chaves = Object.keys(emails);

	if (chaves.length) {
		return (
			<>
				<label style={{ fontWeight: props.render.name !== "Entry" ? "initial" : "bold" }}>
					{props.t("emails.Adicionais")}
				</label>
				<p style={{ lineHeight: 0, color: "rgba(0,0,0,0.6)" }}>
					{props.t("adicione.emails.adicionais")}
				</p>
				<button
					style={{ display: "none" }}
					onClick={e => {
						e.preventDefault();
					}}
				/>
				{_.map(emails, (email, index) => {
					return (
						<div key={index} style={{ display: "flex", flexDirection: "row" }}>
							<Render
								{...props}
								render={props.render}
								required={false}
								name={index}
								width={"95%"}
								// label={index}
								text={value[index]}
								{...props.disableFields}
							/>
							{props.render.name !== "Entry" && (
								<Button
									animated="vertical"
									style={{
										height: 40,
										marginTop: "auto",
										marginBottom: 10,
										marginLeft: 10,
									}}
									onClick={e => {
										e.preventDefault();
										removerEmail(index);
										props.onChange({ deletar: index });
									}}>
									<Button.Content hidden>{props.t("excluir")}</Button.Content>
									<Button.Content visible>
										<Icon name="delete" />
									</Button.Content>
								</Button>
							)}
						</div>
					);
				})}
				{chaves.length < 3 && props.render.name !== "Entry" && adicionar()}
			</>
		);
	} else {
		return props.render.name !== "Entry" ? adicionar() : <></>;
	}
};
