import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Collapse,
	Flex,
	Link,
	Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import firebase from "firebase";
import { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { InputForm } from "../../../Componentes/Form/chakraUI/InputForm";
import { PATHS } from "../../../Utils/Routes";
import UnauthenticatedPageLayout from "../Layout";

const LOGIN_SCHEMA = z.object({
	email: z.string().email("E-mail inválido"),
	password: z.string().min(6, "Senha deve ter no mínimo 6 caracteres"),
});

type LoginFormData = z.infer<typeof LOGIN_SCHEMA>;

export default function LoginPage() {
	const { t } = useTranslation();
	const [loginErrorMessage, setLoginErrorMessage] = useState("");

	const formMethods = useForm<LoginFormData>({
		resolver: zodResolver(LOGIN_SCHEMA),
		defaultValues: {
			email: "",
			password: "",
		},
	});
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const error = params.get("error");
		if (error === "sem-empresa") {
			setLoginErrorMessage("Nenhuma empresa encontrada");
		} else {
			setLoginErrorMessage("");
		}
	}, []);
	const onSubmit: SubmitHandler<LoginFormData> = async data => {
		try {
			setLoginErrorMessage("");
			await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
		} catch (e) {
			console.error(e);

			const error = e as Omit<firebase.firestore.FirestoreError, "code"> & {
				code: firebase.firestore.FirestoreErrorCode | string;
			};

			if (error?.code) {
				const codeToUse =
					error.code === "auth/user-not-found" ? "auth/wrong-password" : error.code;

				setLoginErrorMessage(t(codeToUse));
			} else {
				toast.error(t("ocorreu-um-erro-inesperado-tente-novamente-mais-tarde"));
			}
		}
	};

	return (
		<UnauthenticatedPageLayout>
			<Flex
				as="form"
				flexDir={"column"}
				gap={5}
				w="full"
				maxW="3xl"
				onSubmit={formMethods.handleSubmit(onSubmit)}>
				<InputForm
					isRequired
					control={formMethods.control}
					name="email"
					label="E-mail"
					placeholder="roberto@gmail.com"
				/>
				<InputForm
					control={formMethods.control}
					isRequired
					name="password"
					label={t("senha")}
					type="password"
				/>
				<Collapse in={!!loginErrorMessage}>
					<Alert status="warning" rounded={"md"}>
						<AlertIcon />
						<Flex flexWrap={"wrap"}>
							<AlertTitle>Atenção!</AlertTitle>
							<AlertDescription>{loginErrorMessage}</AlertDescription>
						</Flex>
					</Alert>
				</Collapse>
				<Button type="submit" size="lg" isLoading={formMethods.formState.isSubmitting}>
					{t("entrar")}
				</Button>
				<Box textAlign={"center"} textColor={"gray.500"}>
					<Text>
						{t("nao.possui.conta")}{" "}
						<Link
							as={RouterLink}
							to={PATHS.unauthenticated.register}
							textColor={"purple.400"}
							fontWeight={"bold"}>
							{t("clique.aqui")}
						</Link>
					</Text>
					<Text mt={1}>
						{t("esqueceu.senha")}{" "}
						<Link
							as={RouterLink}
							to={PATHS.unauthenticated.forgotPassword}
							textColor={"purple.400"}
							fontWeight={"bold"}>
							{t("clique.aqui")}
						</Link>
					</Text>
				</Box>
			</Flex>
		</UnauthenticatedPageLayout>
	);
}
