import firebase from "firebase";
import _ from "lodash";
import moment from "moment";
import publicIp from "public-ip";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { auth, db } from "./config";
import Rotas from "./Rotas";
import { chamarFunction } from "./Utils/Api";
import { todayYYYYMMDD, extremesOfTheWeek } from "./Utils/Date.utils";
// import { dataFromSnap } from './Utils/Firebase';
import {
	buscarOportunidadesFaturamentoMensalMongoDB,
	downloadConfiguracao,
	downloadMeusPromotores,
	downloadOportunidadesEmFaturamentoMensal,
	downloadRoteirosExecutados,
	downloadRoteirosFinalizadosNaSemana as downloadRoteirosFinalizadosNaSemana,
	downloadRoteirosNovos,
	downloadRoteirosPendentesEmExecucaoComPendencia,
} from "./Utils/Oportunidades";
import { roteirosComNotaAgencia } from "./Utils/Roteiros";
import {
	alternarModulo,
	atualizarEmpresa,
	atualizarFaturamentoMensal,
	atualizarInfoContaEmpresa,
	atualizarMercado,
	atualizarSaldos,
	autenticarUsuario,
	desautenticaUsuario,
	guardarIPLocation,
	salvarPromotores,
	atualizarOportunidadesMongo,
	atualizarOportunidadesFirebase,
} from "./_actions/AutenticacaoActions";
import { configuracao, notasPendentes } from "./_actions/RecursoActions";
import {
	oportunidadesExecutadasAction,
	oportunidadesFinalizadasNaSemanaAction,
	oportunidadesNovas,
	oportunidadesPendentesEmExecucaoComPendencia,
} from "./_actions/RoteiroActions";
import { buscarRoteirosMensal } from "./_actions/GastoMensalActions";
import { CADASTRANDO } from "./_actions/Types";

const firebaseDef = firebase;
const momentDef = moment;
/**
 * @name setDataDeCriacao
 * @date Criado em 21/12/2020
 * @author Vitor Andre Savian <contato.diegojimenes@gmail.com>
 * @description coloca o criadoEm no doc caso não tenha
 * @param {Object} empresa informações da empresa
 * @param {Object} usuario informações do usuario
 * @param {Function} firebase firebase
 * @param {Function} moment moment :x
 */
export const setDataDeCriacao = (empresa, usuario, firebase = firebaseDef, moment = momentDef) => {
	let criadoEm = moment(usuario.metadata.creationTime).format("YYYY-MM-DD");
	return firebase
		.firestore()
		.collection("empresas")
		.doc(empresa.uid)
		.update({ criadoEm: criadoEm })
		.then(data => {
			return data;
		})
		.catch(err => {
			console.log("err", err);
			return err;
		});
};

/**
 * @name Aplicacao
 * @date Alterado em 21/12/2020
 * @description retorna o JSX do app e coordena as informações pro Redux
 */

class Aplicacao extends Component {
	componentDidMount() {
		this.getIp();
		auth.onAuthStateChanged(this.onAuthStateChanged.bind(this), error => {
			console.error(error);
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.usuario && this.props.usuario) {
			this.carregarInformacoesDaEmpresa(this.props.usuario);
		}
		if (!prevProps.empresa && this.props.empresa) {
			const id = this.props.empresa.uid;
			this.downloadRecursos(this.props.empresa);
			this.infoConta(id).then(valor => this.props.atualizarSaldos("total", valor));
		}
	}

	/**
	 * @name infoConta
	 * @description faz download dos recursos que serão usados no sistema
	 * @param {String} id id da empresa para pegar as informações
	 */
	infoConta = async id => {
		const infoConta = await chamarFunction("informacoesConta", { empresa: true, id });
		let valor = 0;
		if (infoConta) {
			this.props.atualizarInfoContaEmpresa(infoConta);
			if (_.includes(infoConta.balance, "R$")) {
				valor = parseFloat(
					infoConta.balance.replace("R$ ", "").replace(".", "").replace(",", ".")
				);
			} else {
				valor = parseFloat(infoConta.balance.replace(",", "").replace(" BRL", ""));
			}
		}
		return valor;
	};

	/**
	 * @name downloadRecursos
	 * @description faz download dos recursos que serão usados no sistema
	 * @param {Object} empresa informações da empresa
	 */
	downloadRecursos = async empresa => {
		// const { dispatch } = this.props;
		const today = todayYYYYMMDD();
		const { startDayOfWeek, endDayOfWeek } = extremesOfTheWeek();
		if (empresa.tipo === 2) {
			downloadMeusPromotores(empresa.uid).onSnapshot(snap =>
				this.props.salvarPromotores(snap.docs)
			);
		}
		if (empresa?.faturamentoMensal) {
			buscarOportunidadesFaturamentoMensalMongoDB(empresa.uid).then(oportunidadesFMMDB => {
				this.props.atualizarOportunidadesMongo(oportunidadesFMMDB);
				this.props.atualizarFaturamentoMensal();
			});
			downloadOportunidadesEmFaturamentoMensal(empresa.uid).onSnapshot(snap => {
				let dataSnap = snap.docs.map(d => d.data());
				this.props.atualizarOportunidadesFirebase(dataSnap);
				this.props.atualizarFaturamentoMensal();
			});
		} else {
			this.props.buscarRoteirosMensal(empresa.uid);
		}
		downloadConfiguracao().onSnapshot(snap => this.props.configuracao(snap));
		roteirosComNotaAgencia(empresa.uid).onSnapshot(snap => this.props.notasPendentes(snap));
		downloadRoteirosNovos(empresa.uid, today).onSnapshot(snap => {
			var removido = _.find(snap.docChanges(), change => change.type === "removed");
			if (removido) {
				this.infoConta(empresa.uid);
			}

			var roteiros = snap.docs.map(doc => doc.data());
			this.props.oportunidadesNovas(roteiros);
			var reduced = _.reduce(
				_.filter(
					roteiros,
					roteiro =>
						roteiro.selecaoAutomatica &&
						roteiro.pagamento.pagarFaturamentoMensal !== true
				),
				(sum, roteiro) => sum + roteiro.valor,
				0
			);
			this.props.atualizarSaldos("reservadoNovo", reduced || 0);
		});

		downloadRoteirosPendentesEmExecucaoComPendencia(empresa.uid, today).onSnapshot(snap => {
			var removido = _.find(snap.docChanges(), change => change.type === "removed");
			if (removido) {
				this.infoConta(empresa.uid);
			}

			var roteiros = snap.docs.map(doc => doc.data());
			this.props.oportunidadesPendentesEmExecucaoComPendencia(roteiros);
			var reduced = _.reduce(
				roteiros,
				(sum, roteiro) => {
					if (
						roteiro.pagamento.novo &&
						roteiro.pagamento.pagarFaturamentoMensal !== true
					) {
						return sum + roteiro.valor;
					}
				},
				0
			);
			this.props.atualizarSaldos("reservado", reduced || 0);
		});
		downloadRoteirosExecutados(empresa.uid, today).onSnapshot(snap => {
			var roteiros = snap.docs.map(doc => doc.data());
			this.props.oportunidadesExecutadasAction(roteiros);
		});

		downloadRoteirosFinalizadosNaSemana(
			empresa.uid,
			startDayOfWeek.format("YYYY-MM-DD")
		).onSnapshot(snap => {
			var roteiros = snap.docs.map(doc => doc.data());
			this.props.oportunidadesFinalizadasNaSemanaAction(roteiros);
		});
	};

	/**
	 * @name onAuthStateChanged
	 * @description na troca de auth
	 * @param {Object} authUsuario usuario após ser feito mudança na auth
	 */

	onAuthStateChanged = authUsuario => {
		// const { dispatch, autenticado } = this.props;
		const { autenticado } = this.props;
		if (autenticado === CADASTRANDO) return;
		var user = firebase.auth().currentUser;
		if (user) {
			user.getIdTokenResult().then(token => {
				if (token.claims && token.claims.tipo === 3) {
					this.carregarInformacoesMercado(token.claims.user_id);
					this.props
						.autenticarUsuario(authUsuario)
						.then(() => this.props.alternarModulo(3));
				} else {
					this.props.autenticarUsuario(authUsuario);
				}
			});
		} else {
			this.props.autenticarUsuario(authUsuario);
		}
	};

	/**
	 * @name carregarInformacoesMercado
	 * @description carrega as informações de mercado
	 * @param {Object} usuario usuario após ser feito a auth
	 */
	carregarInformacoesMercado = usuario => {
		return db
			.collection("mercados")
			.doc(usuario)
			.onSnapshot(s => {
				var data = s.data();
				this.props.atualizarMercado(data);
			});
	};

	/**
	 * @name carregarInformacoesDaEmpresa
	 * @date Alterado em 20/09/2024
	 * @description carrega as informações da empresa
	 * @param {Object} usuario usuario após ser feito a auth
	 */
	carregarInformacoesDaEmpresa = usuario => {
		return db
			.collection("empresas")
			.where("usuarios", "array-contains", usuario.uid)
			.onSnapshot(
				doc => {
					console.log("Snapshot recebido:", doc);

					// doc.size !== 1, enviar para o cadastro de empresa
					if (doc.size === 1) {
						let empresa = doc.docs[0].data();
						if (empresa.criadoEm === undefined) {
							setDataDeCriacao(empresa, usuario);
						}
						this.props.atualizarEmpresa(empresa);
					} else {
						auth.signOut();
						this.props.desautenticaUsuario();
						window.location.href = "/login?error=sem-empresa";
					}
				},
				err => {
					console.log(err);
					auth.signOut();
					this.props.desautenticaUsuario();
				}
			);
	};

	getIp = async () => {
		try {
			const ip = await publicIp.v4();
			this.props.guardarIPLocation({ ip: ip || "ip nao localizado" });
		} catch (err) {
			this.props.guardarIPLocation({ ip: "ip nao localizado" });
		}
	};

	render() {
		return (
			<Rotas
				firebaseReady={this.props.firebaseReady}
				usuario={this.props.usuario}
				autenticado={this.props.autenticado}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.AutenticacaoReducer,
		...state.DashboardReducer,
		oportunidadesExecutadas: state.RoteiroReducer.oportunidadesExecutadas,
	};
};

export default withTranslation()(
	connect(mapStateToProps, {
		alternarModulo,
		autenticarUsuario,
		desautenticaUsuario,
		atualizarEmpresa,
		atualizarMercado,
		configuracao,
		notasPendentes,
		salvarPromotores,
		atualizarInfoContaEmpresa,
		oportunidadesNovas,
		oportunidadesPendentesEmExecucaoComPendencia,
		atualizarSaldos,
		atualizarFaturamentoMensal,
		oportunidadesExecutadasAction,
		oportunidadesFinalizadasNaSemanaAction,
		atualizarOportunidadesMongo,
		guardarIPLocation,
		buscarRoteirosMensal,
		atualizarOportunidadesFirebase,
	})(Aplicacao)
);
